import React from 'react'

interface IProps {
    className?: string
    charge_low?: boolean
    charge_fair?: boolean
    charge_high?: boolean
    color_low?: string
    color_fair?: string
    color_high?: string
}

const ThreeColorStack: React.FC<IProps> = ({
    className,
    charge_low,
    charge_fair,
    charge_high,
    color_low,
    color_high,
    color_fair,
}) => {
    return (
        <div className={`space-y-[1px] ${className}`}>
            <div
                className={`w-[4px] h-[3px] ${
                    charge_high ? color_high ?? 'bg-primary dark:bg-dark_primary' : 'bg-gray05 dark:bg-dark_gray05'
                }`}></div>
            <div
                className={`w-[4px] h-[3px] ${
                    charge_high
                        ? color_high ?? 'bg-primary dark:bg-dark_primary'
                        : charge_fair
                        ? color_fair ?? 'bg-blue dark:bg-dark_blue'
                        : 'bg-gray05 dark:bg-dark_gray05'
                }`}></div>
            <div
                className={`w-[4px] h-[3px] ${
                    charge_high
                        ? color_high ?? 'bg-primary dark:bg-dark_primary'
                        : charge_fair
                        ? color_fair ?? 'bg-blue dark:bg-dark_blue'
                        : charge_low
                        ? color_low ?? 'bg-red dark:bg-dark_red'
                        : 'bg-gray05 dark:bg-dark_gray05'
                }`}></div>
        </div>
    )
}

export default ThreeColorStack
