import React, {useMemo} from 'react'
import dynamic from 'next/dynamic'
import {IUserGraph} from '@api/user/profile/getUserGraph'
import {AreaChartData} from '@component/charts/WizAreaChart/WizAreaChart'
import {getDateFromYYYYMMDD} from '@util/dates'
import WizPortfolioAreaChart from '@component/charts/WizPortfolioAreaChart/WizPortfolioAreaChart'
import Spinner from '@component/Spinner/Spinner'

const ApexChart = dynamic(() => import('react-apexcharts'), {ssr: false})

interface IProps {
    height?: number
    input?: IUserGraph[]
    isFetching?: boolean
}

const SidebarBalanceChart: React.FC<IProps> = ({height, input = [], isFetching}) => {
    const chartData = useMemo(() => {
        if (!input || input.length == 0) {
            return [
                // {x: 0, y: 0},
                // {x: 0, y: 0},
            ] as AreaChartData[]
        }
        const list = []

        input.map(item => {
            if (item?.balance) {
                list?.push({x: getDateFromYYYYMMDD(item.day).getTime(), y: item.balance})
            }
        })

        return list
    }, [input])

    if (isFetching) {
        return (
            <div className={'mt-[10px] h-[45px]'}>
                <Spinner visible={isFetching} className={'mx-auto'} />
            </div>
        )
    }

    if (chartData?.length === 0) return <></>

    return (
        <div className={'mt-[10px]'}>
            <WizPortfolioAreaChart chartData={chartData} chartType={'balance'} isSimple={true} height={height} />
        </div>
    )
}

export default React.memo(SidebarBalanceChart)
