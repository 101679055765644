import React, {useMemo} from 'react'
import Text from '@component/Text/Text'
import IconProfit from '@svg/common/ic_profit.svg'
import IconMdd from '@svg/common/ic_mdd.svg'
import IconHold from '@svg/common/ic_hold.svg'
import IconTrade from '@svg/common/ic_trade.svg'
import {useTranslation} from 'next-i18next'
import useFormatNumber from '@hook/useFormatNumber'
import RiskTag from '@component/tags/RiskTag'
import Info from '@svg/common/ic_info.svg'
import WizTooltip from '@component/tooltip/WizTooltip'
import {differenceInHours} from 'date-fns'
import {getDayDifference} from '@util/dates'
import TagText from '@component/Text/TagText'
import IconInfoDark from '@svg/common/ic_info_dark.svg'
import useDark from '@hook/useDark'
import SkeletonBlock from '@component/skeleton/SkeletonBlock'

interface IProps {
    className?: string
    winTrade?: number
    loseTrade?: number
    mdd?: number
    avgHold?: number
    avgTradePerMonth?: number
    created_at?: string
    risk?: number
    notRenderStartAt?: boolean
    isFetching?: boolean
    contentClassName?: string
}

const TradingStyleContainer: React.FC<IProps> = ({
    className,
    risk,
    winTrade,
    loseTrade,
    mdd,
    avgHold,
    avgTradePerMonth,
    created_at,
    notRenderStartAt,
    isFetching,
    contentClassName,
}) => {
    const {t} = useTranslation()
    const {displayPercent, displayAmount, displayNumber} = useFormatNumber()
    const {isRenderDark} = useDark()

    const getWinningRatio = () => {
        const totalTrade = winTrade + loseTrade
        if (totalTrade == 0) {
            return 0.0
        }
        return (winTrade / totalTrade) * 100
    }

    const startedHoursAgo = useMemo(() => differenceInHours(new Date(), new Date(created_at)), [created_at])

    const startedDaysAgo = useMemo(() => getDayDifference(new Date(Date.parse(created_at)), new Date()), [created_at])

    return (
        <div
            className={`shadow-normal py-[30px] px-[20px] rounded-[5px] bg-white dark:bg-bg_dark_white01 md:col-span-2 ${className} overflow-hidden`}>
            <div className={'flex items-center justify-between'}>
                <div className={'flex gap-x-[5px]'}>
                    <Text className={'text-ti1 text-gray01 dark:text-dark_gray01'}>
                        {t('portfolio.dashboard.tradingStyle.title')}
                    </Text>
                    <WizTooltip
                        type={'portfolio_trading_style'}
                        title={t('portfolio.dashboard.tradingStyle.tooltip.title')}
                        render={
                            isRenderDark ? (
                                <IconInfoDark className={'w-[20px] fill-bg_dark_white03 hover:fill-bg_dark_white01'} />
                            ) : (
                                <Info className={'w-[20px] fill-white hover:fill-gray08'} />
                            )
                        }>
                        <Text className={'text-body3 font-bold text-gray02 dark:text-dark_gray02'}>
                            {t('portfolio.dashboard.tradingStyle.tooltip.profitRatio.title')}
                        </Text>
                        <Text className={'text-body3 text-gray02 dark:text-dark_gray02 mt-[5px]'}>
                            {t('portfolio.dashboard.tradingStyle.tooltip.profitRatio.desc')}
                        </Text>
                        <Text className={'text-body3 font-bold text-gray02 dark:text-dark_gray02 mt-[10px]'}>
                            {t('portfolio.dashboard.tradingStyle.tooltip.mdd.title')}
                        </Text>
                        <Text className={'text-body3 text-gray02 dark:text-dark_gray02 mt-[5px]'}>
                            {t('portfolio.dashboard.tradingStyle.tooltip.mdd.desc')}
                        </Text>
                        <Text className={'text-body3 font-bold text-gray02 dark:text-dark_gray02 mt-[10px]'}>
                            {t('portfolio.dashboard.tradingStyle.tooltip.holdAvg.title')}
                        </Text>
                        <Text className={'text-body3 text-gray02 dark:text-dark_gray02 mt-[5px]'}>
                            {t('portfolio.dashboard.tradingStyle.tooltip.holdAvg.desc')}
                        </Text>
                        <Text className={'text-body3 font-bold text-gray02 dark:text-dark_gray02 mt-[10px]'}>
                            {t('portfolio.dashboard.tradingStyle.tooltip.30dTradeFrequency.title')}
                        </Text>
                        <Text className={'text-body3 text-gray02 dark:text-dark_gray02 mt-[5px]'}>
                            {t('portfolio.dashboard.tradingStyle.tooltip.30dTradeFrequency.desc')}
                        </Text>
                    </WizTooltip>
                </div>
                {!notRenderStartAt && created_at && (
                    <TagText className={'text-body3 text-gray02 dark:text-dark_gray02'}>
                        {startedHoursAgo < 24
                            ? t('portfolio.dashboard.tradingStyle.startedHourAgo', {
                                  count: startedHoursAgo,
                              })
                            : t('portfolio.dashboard.tradingStyle.startedAgo', {
                                  day: startedDaysAgo,
                              })}
                    </TagText>
                )}
            </div>
            <div className={`grid grid-cols-2 mt-[15px] gap-y-[10px] ${contentClassName}`}>
                <div className={'flex items-center p-[10px]'}>
                    <div className={'shadow-normal p-[10px] rounded-[5px]'}>
                        <IconProfit className={'w-[20px] fill-gray02 dark:fill-dark_gray02'} />
                    </div>
                    <div className={'ml-[15px]'}>
                        <Text className={'text-body_comm font-bold text-gray03 dark:text-dark_gray03'}>
                            {t('portfolio.dashboard.tradingStyle.profitRatio.title')}
                        </Text>
                        {isFetching ? (
                            <SkeletonBlock className={'w-[50px] h-[24px]'} sizeClassName={'h-[15px]'} />
                        ) : (
                            <Text className={'text-ti1 text-gray01 dark:text-dark_gray01'}>
                                {t('portfolio.dashboard.tradingStyle.profitRatio.value', {
                                    ratio: displayPercent(getWinningRatio()),
                                })}
                            </Text>
                        )}
                    </div>
                </div>
                <div className={'flex items-center p-[10px]'}>
                    <div className={'shadow-normal p-[10px] rounded-[5px]'}>
                        <IconMdd className={'w-[20px] fill-gray02 dark:fill-dark_gray02'} />
                    </div>
                    <div className={'ml-[15px]'}>
                        <Text className={'text-body_comm font-bold text-gray03 dark:text-dark_gray03'}>
                            {t('portfolio.dashboard.tradingStyle.mdd.title')}
                        </Text>
                        {isFetching ? (
                            <SkeletonBlock className={'w-[50px] h-[24px]'} sizeClassName={'h-[15px]'} />
                        ) : (
                            <div className={'flex items-center'}>
                                <Text className={'text-ti1 text-gray01 dark:text-dark_gray01'}>
                                    {displayPercent(mdd)}
                                </Text>
                                {risk ? <RiskTag risk={risk || 1} className={'ml-[5px] sm:hidden'} /> : <></>}
                            </div>
                        )}
                    </div>
                </div>
                <div className={'flex items-center p-[10px]'}>
                    <div className={'shadow-normal p-[10px] rounded-[5px]'}>
                        <IconHold className={'w-[20px] fill-gray02 dark:fill-dark_gray02'} />
                    </div>
                    <div className={'ml-[15px]'}>
                        <Text className={'text-body_comm font-bold text-gray03 dark:text-dark_gray03'}>
                            {t('portfolio.dashboard.tradingStyle.holdAverage.title')}
                        </Text>
                        {isFetching ? (
                            <SkeletonBlock className={'w-[50px] h-[24px]'} sizeClassName={'h-[15px]'} />
                        ) : (
                            <Text className={'text-ti1 text-gray01 dark:text-dark_gray01'}>
                                {t('portfolio.dashboard.tradingStyle.holdAverage.value', {
                                    day: displayNumber(avgHold / 86400, 2),
                                })}
                            </Text>
                        )}
                    </div>
                </div>
                <div className={'flex items-center p-[10px]'}>
                    <div className={'shadow-normal p-[10px] rounded-[5px]'}>
                        <IconTrade className={'w-[20px] fill-gray02 dark:fill-dark_gray02'} />
                    </div>
                    <div className={'ml-[15px]'}>
                        <Text className={'text-body_comm font-bold text-gray03 dark:text-dark_gray03'}>
                            {t('portfolio.dashboard.tradingStyle.30dTradeFrequency.title')}
                        </Text>
                        {isFetching ? (
                            <SkeletonBlock className={'w-[50px] h-[24px]'} sizeClassName={'h-[15px]'} />
                        ) : (
                            <Text className={'text-ti1 text-gray01 dark:text-dark_gray01'}>
                                {t('portfolio.dashboard.tradingStyle.30dTradeFrequency.value', {
                                    times: displayAmount(avgTradePerMonth ?? 0),
                                })}
                            </Text>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TradingStyleContainer
