import React, {useMemo} from 'react'
import dynamic from 'next/dynamic'
import {IKeyGraph} from '@api/user/profile/getKeyGraph'
import WizPortfolioAreaChart from '@component/charts/WizPortfolioAreaChart/WizPortfolioAreaChart'

const ApexChart = dynamic(() => import('react-apexcharts'), {ssr: false})

interface IProps {
    className?: string
    width?: number
    height?: number
    input?: IKeyGraph[]
}

const AssetTableHeaderKeyGraph: React.FC<IProps> = ({width, height, input = [], className}) => {
    const chartData = useMemo(() => {
        const list = []
        input?.map(item => {
            list.push({x: '', y: item?.score})
        })
        return list
    }, [input])

    return (
        <div className={className}>
            <div style={{width: width}} className={''}>
                <WizPortfolioAreaChart
                    chartData={chartData}
                    className={'!mt-[0px]'}
                    chartType={'profit'}
                    height={height}
                    isSimple={true}
                    borderWidth={1.5}
                />
            </div>
        </div>
    )
}

export default AssetTableHeaderKeyGraph
